require('jquery');
require('jquery-ui');
require('bootstrap');

require('alpinejs');

require('vue/dist/vue.min.js');

import * as library from 'feather-icons';

require('vue-tree-chart');

//new Vue({
//    el: '#app'
//});
//require('video.js');

const feather = window.feather = require('feather-icons');
//feather = require('feather-icons');
feather.replace();

import $ from 'jquery';
window.$ = window.jQuery = $;

import videojs from 'video.js';
window.videojs = videojs;

window.d3=require('d3');
window.d3.mitchTree=require('d3-mitch-tree');

import 'jquery-ui/ui/widgets/datepicker.js';
require('chart.js/dist/chart.min.js');
//const feather = require('feather-icons');

//const bsCustomFileInput=window.bsCustomFileInput=require('bs-custom-file-input/dist/bs-custom-file-input.min.js');
const bsCustomFileInput=window.bsCustomFileInput=require('bs-custom-file-input');
$('.datepicker').datepicker();
$( "#datepicker" ).datepicker();